import DealsService from "@/services/market/deals.service";



export const deal = {
    namespaced: true,

    state: {},

    actions: {
        getDeals({ commit }, data) {
            return DealsService.getDeals(data).then((data) => {
                commit("deals", data);
                return Promise.resolve(data);
            });
        },
        getDealShow({ commit }, data) {
            return DealsService.getDealShow(data).then((data) => { 
                commit("deal_show", data);
                return Promise.resolve(data);
            });
        },
    },

    mutations: {
        deals(state, data) {
            state.deals = data;
        },
        deal_show(state, data) {
            state.deal_show = data;
        }
    }


    

}
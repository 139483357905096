import helpers from '@/helpers/helper';
import Axios from '../Axios';

const API_URL = helpers.API_ENDPOINT + 'ticket/';

class ticketService {
    getAllTickets(data) {
      return Axios({
        method  : 'POST',
        url     : API_URL + 'ticket-list' + '?page=' + data.page + '&?size=' + data.size,
        data    : data
      });
    }
    getTicket(data) {
      return Axios({
        method  : 'GET',
        url     : API_URL + 'ticket/' + data.id,
      });
    }
    postTicket(data) { 
      return Axios({
        method  : 'POST',
        url     : API_URL + 'ticket',
        data    : data,
      });
    }
    patchTicket(data) { 
      return Axios({
        method  : 'PATCH',
        url     : API_URL + 'ticket/' + data.id,
        data    : data,
      });
    }
    deleteTicket(data) { 
      return Axios({
        method  : 'DELETE',
        url     : API_URL + 'ticket/' + data.id,
      });
    }
  }
export default new ticketService();
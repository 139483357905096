import helpers from '@/helpers/helper';
import Axios from '../Axios';

const API_URL = helpers.API_ENDPOINT + 'ticket/';

class ticketStatusService {
    getAllTicketStatuses(data) {
      return Axios({
        method  : 'GET',
        url     : API_URL + 'status',
        data    : data
      });
    }
    getTicketStatus(data) {
      return Axios({
        method  : 'POST',
        url     : API_URL + 'status/' + data.id,
        data    : data,
      });
    }
    postTicketStatus(data) { 
      return Axios({
        method  : 'POST',
        url     : API_URL + 'status',
        data    : data,
      });
    }
    patchTicketStatus(data) { 
      return Axios({
        method  : 'PATCH',
        url     : API_URL + 'status/' + data.id,
        data    : data,
      });
    }
    deleteTicketStatus(data) { 
      return Axios({
        method  : 'DELETE',
        url     : API_URL + 'status/' + data.id,
      });
    }
  }
export default new ticketStatusService();
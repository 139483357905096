import axios from "axios";
import { _authHeader } from "../auth._header";
import helpers from "@/helpers/helper";

const API_URL = helpers.API_ENDPOINT;

class AnalyticsService {
    getIndividualSales(data) {
        // console.log("This is the pararms for individuals", data);
        return axios.get(API_URL + 'analytics/individual', { params: data, headers: _authHeader(), }).then((response) => {
            return response.data.data;
        });
    };
    getConvertionRate(data) {
        return axios.get(API_URL + 'analytics/convertion-rate', { params: data, headers: _authHeader(), }).then((response) => {
            return response.data.data;
        });
    };
    getPipeleine(data) {
        return axios.get(API_URL + 'analytics/pipeline-check', { params: data, headers: _authHeader(), }).then((response) => {
            return response.data.data;
        });
    };
}

export default new AnalyticsService();
<template>
  <div class="main-wrapper login-body">
    <div class="login-wrapper">
      <div class="container">
        <img
          class="img-fluid logo-dark"
          src="/assets/img/logo.png"
          alt="Logo"
        />
        <el-card class="loginbox">
          <el-form
            :model="user"
            size="large"
            ref="user"
            label-position="top"
            inline
            status-icon
          >
            <!-- start: authentication -->
            <el-row class="p-3" v-if="authenticated">
              <el-col
                :xs="24"
                :sm="24"
                :md="24"
                :lg="24"
                :xl="24"
                class="text-center p-1"
              >
                <h5>Sign In</h5>
                <p>Admin ICP Portal</p>
              </el-col>
              <el-col
                style="margin-top: 10px"
                :xs="24"
                :sm="24"
                :md="24"
                :lg="24"
                :xl="24"
              >
                <el-form-item
                  prop="email"
                  :rules="[
                    {
                      required: true,
                      message: 'Email address is required',
                      trigger: ['blur'],
                    },
                    {
                      type: 'email',
                      message: 'Please input correct email address',
                      trigger: ['blur', 'change'],
                    },
                  ]"
                >
                  <el-input
                    v-model="user.email"
                    type="email"
                    autocomplete="off"
                    placeholder="Email"
                    @keydown="clearError"
                  >
                    <template #prepend>
                      <span class="text-primary me-1" style="min-width: 30px:"
                        ><i class="fa fa-envelope"></i
                      ></span>
                    </template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col
                style="margin-top: 10px"
                :xs="24"
                :sm="24"
                :md="24"
                :lg="24"
                :xl="24"
              >
                <el-form-item
                  prop="password"
                  :rules="[
                    {
                      required: true,
                      message: 'Password is required',
                      trigger: ['blur'],
                    },
                  ]"
                >
                  <el-input
                    v-model="user.password"
                    type="password"
                    autocomplete="off"
                    placeholder="Password"
                    @keydown="clearError"
                  >
                    <template #prepend>
                      <span class="text-primary me-1" style="min-width: 30px:"
                        ><i class="fa fa-lock"></i
                      ></span>
                    </template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col
                :xs="24"
                :sm="24"
                :md="24"
                :lg="24"
                :xl="24"
                class="text-center"
                v-if="credentialCheck"
              >
                <small class="text-danger">{{ credentialCheckResponse }}</small>
              </el-col>
              <el-col
                :xs="24"
                :sm="24"
                :md="24"
                :lg="24"
                :xl="24"
                class="p-2 text-end"
              >
                <router-link to="/forgotPassword" class="forgot-link"
                  >Forgot Password?</router-link
                >
              </el-col>
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-form-item label="">
                  <el-button
                    type="primary"
                    class="w-100"
                    @click="handleLogin('user')"
                    :loading="loading"
                    >Sign in</el-button
                  ></el-form-item
                >
              </el-col>
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <div style="margin-bottom: 30px"></div>
              </el-col>
            </el-row>
            <!-- end: authentication -->

            <!-- start; verification -->
            <el-row class="p-3" v-else>
              <el-col
                :xs="24"
                :sm="24"
                :md="24"
                :lg="24"
                :xl="24"
                class="text-center p-1"
              >
                <h5>Verify</h5>
                <span>Help us protect your account</span>
                <span class="or-line"></span>
              </el-col>
              <el-col
                :xs="24"
                :sm="24"
                :md="24"
                :lg="24"
                :xl="24"
                class="text-center"
                style="margin-top: 10px"
              >
                <!-- <h3>Verification</h3> -->
                <small>
                  For added security, you'll need to verify your identity. We've
                  sent a verification code to <b>{{ maskedEmail }}</b
                  >. The code will expire in an hour.
                </small>
              </el-col>
              <el-col
                style="margin-top: 10px"
                :xs="24"
                :sm="24"
                :md="24"
                :lg="24"
                :xl="24"
              >
                <el-form-item
                  prop="auth_code"
                  :rules="[
                    {
                      required: true,
                      message: 'Verification code is required',
                      trigger: ['blur'],
                    },
                  ]"
                >
                  <el-input
                    v-model="user.auth_code"
                    type="password"
                    autocomplete="off"
                    placeholder="Enter verification code"
                    @keydown="clearError"
                  >
                    <template #prepend>
                      <span class="text-primary me-1" style="min-width: 30px:"
                        ><i class="fa fa-lock-open"></i
                      ></span>
                    </template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col
                :xs="24"
                :sm="24"
                :md="24"
                :lg="24"
                :xl="24"
                class="text-center"
                v-if="credentialCheck"
              >
                <small class="text-danger">{{ credentialCheckResponse }}</small>
              </el-col>
              <el-col
                :xs="24"
                :sm="24"
                :md="24"
                :lg="24"
                :xl="24"
                class="p-2 text-end"
              >
                <router-link
                  to="#"
                  @click="
                    (user.auth_code = false),
                      (resend = true),
                      handleLogin('user')
                  "
                  class="forgot-link"
                  >Resend code?</router-link
                ></el-col
              >
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-form-item label="">
                  <el-button
                    type="primary"
                    class="w-100"
                    @click="handleVerify('user')"
                    :loading="loading"
                    >Verify</el-button
                  ></el-form-item
                >
              </el-col>
              <br />
              <el-col
                :xs="24"
                :sm="24"
                :md="24"
                :lg="24"
                :xl="24"
                class="text-center"
                style="margin-top: 10px"
              >
                <small
                  >If you lost access to your email or have trouble with the
                  code,<br/>
                  <router-link to="/steps-to-recover-account" >here are some other steps you can take.</router-link>
                </small>
              </el-col>
            </el-row>
            <!-- end; verification -->
          </el-form>
        </el-card>
      </div>
      <div class="text-center">
        <small>&copy; {{ $helpers.copyright }}</small
        ><br />
        <small>{{ $helpers.version }}</small>
      </div>
    </div>
  </div>
</template>

<script >
export default {
  data() {
    return {
      loading: false,
      user: {},
      message: "",
      authenticated: true,
      credentialCheck: false,
      credentialCheckResponse: "",
      maskedEmail: "",
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    this.returnUrl = this.$route.query.returnUrl || "/";
    if (this.loggedIn) {
      return this.$router.push(this.returnUrl);
    }
  },
  methods: {
    logout() {
      localStorage.removeItem("_user");
    },

    handleLogin(validate) {
      this.$refs[validate].validate((valid) => {
        if (valid) {
          this.loading = true;
          let user = this.user;
          this.$store.dispatch("auth/signin", user).then(
            (response) => {
              if (response != undefined) {
                if (response.status == 401) {
                  this.authenticated = true;
                  this.loading = false;
                  this.credentialCheck = true;
                  this.credentialCheckResponse = response.message;
                } else {
                  this.authenticated = false;
                  this.credentialCheck = false;
                  this.loading = false;
                  this.maskedEmail = this.$helpers.maskEmail(user.email);
                }
              } else {
                this.credentialCheck = true;
                this.loading = false;
                this.user = {};
              }
            },
            (error) => {
              this.authenticated = true;
              this.credentialCheck = true;
              this.credentialCheckResponse = error.response.data.message;
              this.loading = false;
            }
          );
        } else {
          return false;
        }
      });
    },
    handleVerify(validate) {
      this.$refs[validate].validate((valid) => {
        if (valid) {
          this.loading = true;
          let user = this.user;
          this.$store.dispatch("auth/verifyOtp", user).then((response) => {
            this.authenticated = false;
            if (response != undefined) {
              if (response.status == 401) {
                this.credentialCheck = true;
                this.credentialCheckResponse = response.message;
                this.user.auth_code = "";
                this.loading = false;
              } else {
                this.user = {};
                this.$notify({
                  title: "Hi!" + " " + response.attributes.name,
                  message: "Welcome Back to Zemo ICP",
                  type: "success",
                });
                this.$router.push(this.returnUrl);
                this.credentialCheck = false;
                this.loading = false;
              }
            } else {
              this.credentialCheck = true;
              this.loading = false;
            }
          });
        } else {
          return false;
        }
      });
    },
    clearError() {
      this.credentialCheck = false;
      this.credentialCheckResponse = "";
    },
  },
};
</script>
import helpers from '@/helpers/helper';
import Axios from '../Axios';

const API_URL = helpers.API_ENDPOINT + 'ticket/';

class ticketNoteService {
    getAllTicketNotes(data) {
      return Axios({
        method  : 'POST',
        url     : API_URL + 'ticket-notes/' + data.ticket_id + '?page=' + data.page + '&?size=' + data.size,
        data    : data
      });
    }
    getTicketNote(data) {
      return Axios({
        method  : 'POST',
        url     : API_URL + 'note/' + data.id,
        data    : data,
      });
    }
    postTicketNote(data) { 
      return Axios({
        method  : 'POST',
        url     : API_URL + 'note',
        data    : data,
      });
    }
    patchTicketNote(data) { 
      return Axios({
        method  : 'PATCH',
        url     : API_URL + 'note/' + data.id,
        data    : data,
      });
    }
    deleteTicketNote(data) { 
      return Axios({
        method  : 'DELETE',
        url     : API_URL + 'note/' + data.id,
      });
    }
  }
export default new ticketNoteService();
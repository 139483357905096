import ticketService from "@/services/ticket/ticket_status.service";

export const ticket_status = {
    namespaced  : true,
    state       : {
        statuses   : [],
    },
    actions : {
        getAllStatuses({ commit }, data) {
            return ticketService.getAllTicketStatuses(data).then((response) => {
                commit("statuses", response.data);
                return Promise.resolve(response);
            });
        },
        postTicket({ commit }, data) {
            return ticketService.postTicket(data).then((response) => {
                commit("status", response.data.data);
                return Promise.resolve(response);
            });
        },
        patchTicket({ commit }, data) {
            return ticketService.patchTicket(data).then((response) => {
                commit("status", response.data.data);
                return Promise.resolve(response);
            });
        },
        deleteTicket({ commit }, data) {
            return ticketService.deleteTicket(data).then((response) => {
                commit("delete", data);
                return Promise.resolve(response);
            });
        },
    },
    mutations: {
        statuses(state, data) {
            state.statuses = data.data;
        },
        status(state, data) {
            for (let index = 0; index < (state.statuses).length; index++) {
                if(state.statuses[index].id == data.id) {
                    state.statuses.splice(index,1,data);
                    return;
                }
            }
            state.statuses.unshift(data);
        },
        delete(state, data) {
            for (let index = 0; index < (state.statuses).length; index++) {
                if(state.statuses[index].id == data.id) {
                    state.statuses.splice(index,1)
                    return;
                }
            }
        }
    },
};
import categoryService from "@/services/ticket/ticket_category.service";

export const ticket_category = {
    namespaced  : true,
    state       : {
    categories  : [],
    },
    actions : {
        getAllCategory({ commit }, data) {
            return categoryService.getAllTicketCategories(data).then((response) => {
                commit("categories", response.data);
                return Promise.resolve(response);
            });
        },
        postCategory({ commit }, data) {
            return categoryService.postCategory(data).then((response) => {
                commit("category", response.data.data);
                return Promise.resolve(response);
            });
        },
        patchCategory({ commit }, data) {
            return categoryService.patchCategory(data).then((response) => {
                commit("category", response.data.data);
                return Promise.resolve(response);
            });
        },
        deleteCategory({ commit }, data) {
            return categoryService.deleteCategory(data).then((response) => {
                commit("delete", data);
                return Promise.resolve(response);
            });
        },
    },
    mutations: {
        categories(state, data) {
            state.categories = data.data;
        },
        category(state, data) {
            for (let index = 0; index < (state.categories).length; index++) {
                if(state.categories[index].id == data.id) {
                    state.categories.splice(index,1,data);
                    return;
                }
            }
            state.categories.unshift(data);
        },
        delete(state, data) {
            for (let index = 0; index < (state.categories).length; index++) {
                if(state.categories[index].id == data.id) {
                    state.categories.splice(index,1)
                    return;
                }
            }
        }
    },
};
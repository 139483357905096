import ticketService from "@/services/ticket/ticket_priority.service";

export const ticket_priority = {
    namespaced  : true,
    state       : {
        notes   : [],
        meta    : [],
    },
    actions : {
        getAllPriorities({ commit }, data) {
            return ticketService.getAllTicketPriorities(data).then((response) => {
                commit("priorities", response.data);
                return Promise.resolve(response);
            });
        },
        postPriority({ commit }, data) {
            return ticketService.postPriority(data).then((response) => {
                commit("priority", response.data.data);
                return Promise.resolve(response);
            });
        },
        patchPriority({ commit }, data) {
            return ticketService.patchPriority(data).then((response) => {
                commit("priority", response.data.data);
                return Promise.resolve(response);
            });
        },
        deletePriority({ commit }, data) {
            return ticketService.deletePriority(data).then((response) => {
                commit("delete", data);
                return Promise.resolve(response);
            });
        },
    },
    mutations: {
        priorities(state, data) {
            state.priorities = data.data;
        },
        priority(state, data) {
            for (let index = 0; index < (state.priorities).length; index++) {
                if(state.priorities[index].id == data.id) {
                    state.priorities.splice(index,1,data);
                    return;
                }
            }
            state.priorities.unshift(data);
        },
        delete(state, data) {
            for (let index = 0; index < (state.priorities).length; index++) {
                if(state.priorities[index].id == data.id) {
                    state.priorities.splice(index,1)
                    return;
                }
            }
        }
    },
};
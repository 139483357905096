import helpers from '@/helpers/helper';
import Axios from '../Axios';

const API_URL = helpers.API_ENDPOINT + 'ticket/';

class ticketPriorityService {
    getAllTicketPriorities(data) {
      return Axios({
        method  : 'GET',
        url     : API_URL + 'priority',
        data    : data
      });
    }
    getTicketPriority(data) {
      return Axios({
        method  : 'POST',
        url     : API_URL + 'priority/' + data.id,
        data    : data,
      });
    }
    postTicketPriority(data) { 
      return Axios({
        method  : 'POST',
        url     : API_URL + 'priority',
        data    : data,
      });
    }
    patchTicketPriority(data) { 
      return Axios({
        method  : 'PATCH',
        url     : API_URL + 'priority/' + data.id,
        data    : data,
      });
    }
    deleteTicketPriority(data) { 
      return Axios({
        method  : 'DELETE',
        url     : API_URL + 'priority/' + data.id,
      });
    }
  }
export default new ticketPriorityService();
import ticketService from "@/services/ticket/ticket.service";

export const ticket = {
    namespaced  : true,
    state       : {
        tickets : [],
        meta    : {},
    },
    actions : {
        getAllTickets({ commit }, data) {
            return ticketService.getAllTickets(data).then((response) => {
                commit("tickets", response.data);
                return Promise.resolve(response);
            });
        },
        getTicket({ commit }, data) {
            return ticketService.getTicket(data).then((response) => {
                commit("ticket", response.data.data);
                return Promise.resolve(response);
            });
        },
        postTicket({ commit }, data) {
            return ticketService.postTicket(data).then((response) => {
                commit("ticket", response.data.data);
                return Promise.resolve(response);
            });
        },
        patchTicket({ commit }, data) {
            return ticketService.patchTicket(data).then((response) => {
                commit("ticket", response.data.data);
                return Promise.resolve(response);
            });
        },
        deleteTicket({ commit }, data) {
            return ticketService.deleteTicket(data).then((response) => {
                commit("delete", data);
                return Promise.resolve(response);
            });
        },
    },
    mutations: {
        tickets(state, data) {
            state.tickets   = data.data;
            state.meta      = data.meta;
        },
        ticket(state, data) {
            for (let index = 0; index < (state.tickets).length; index++) {
                if(state.tickets[index].id == data.id) {
                    state.tickets.splice(index,1,data);
                    state.ticket = data;
                    return;
                }
            }
            state.tickets.unshift(data);
        },
        delete(state, data) {
            for (let index = 0; index < (state.tickets).length; index++) {
                if(state.tickets[index].id == data.id) {
                    state.tickets.splice(index,1)
                    return;
                }
            }
        }
    },
};
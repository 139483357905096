import ticketService from "@/services/ticket/ticket_notes.service";

export const ticket_notes = {
    namespaced  : true,
    state       : {
        notes   : [],
        meta    : {},
    },
    actions : {
        getAllTicketNotes({ commit }, data) { 
            return ticketService.getAllTicketNotes(data).then((response) => {
                commit("notes", response.data);
                return Promise.resolve(response);
            });
        },
        postTicketNotes({ commit }, data) {
            return ticketService.postTicketNote(data).then((response) => {
                commit("note", response.data.data);
                return Promise.resolve(response);
            });
        },
        patchTicketNotes({ commit }, data) {
            return ticketService.patchTicketNote(data).then((response) => {
                commit("note", response.data.data);
                return Promise.resolve(response);
            });
        },
        deleteTicketNotes({ commit }, data) {
            return ticketService.deleteTicketNote(data).then((response) => {
                commit("delete", data);
                return Promise.resolve(response);
            });
        },
    },
    mutations: {
        notes(state, data) {
            state.notes = data.data;
            state.meta = data.meta;
        },
        note(state, data) {
            for (let index = 0; index < (state.notes).length; index++) {
                if(state.notes[index].id == data.id) {
                    state.notes.splice(index,1,data);
                    return;
                }
            }
            state.notes.unshift(data);
        },
        delete(state, data) {
            for (let index = 0; index < (state.notes).length; index++) {
                if(state.notes[index].id == data.id) {
                    state.notes.splice(index,1)
                    return;
                }
            }
        }
    },
};